import { settingsService } from "@services/settings.service";

const GPT_ID = settingsService.options.google_publisher_id || "22745219596";

// ad sizes
const billboard: [number, number] = [970, 250];
const leaderboard: [number, number] = [728, 90];
const mdRectangle: [number, number] = [300, 250];
const halfPage: [number, number] = [300, 600];

// viewports
const fullHd: [number, number] = [1920, 400];
// unused here const xxl: [number, number] = [1600, 400];
const inlineXxl: [number, number] = [1412, 400];
const xl: [number, number] = [1300, 400];
const lg: [number, number] = [1066, 400]; // 1024 + padding
const md: [number, number] = [776, 250]; // 768 + padding
const sm: [number, number] = [0, 0];

const allSizes = [billboard, leaderboard, mdRectangle];

const headerSizeMapping: CIAdSize[] = [
  { viewport: lg, sizes: billboard },
  { viewport: md, sizes: leaderboard },
  { viewport: sm, sizes: mdRectangle },
];
const sidebarTopSizeMapping: CIAdSize[] = [
  { viewport: xl, sizes: halfPage },
  { viewport: sm, sizes: [] },
];
const sidebar2ndSizeMapping: CIAdSize[] = [
  { viewport: xl, sizes: mdRectangle },
  { viewport: sm, sizes: [] },
];
const inlineSizeMapping: CIAdSize[] = [
  { viewport: fullHd, sizes: billboard },
  { viewport: inlineXxl, sizes: leaderboard },
  { viewport: xl, sizes: mdRectangle },
  { viewport: lg, sizes: billboard },
  { viewport: md, sizes: leaderboard },
  { viewport: sm, sizes: mdRectangle },
];

const headerSlot = (slotId: string, slotName: string, member: boolean = false): CIAdsMeta => ({
  path: `/${GPT_ID}/${slotId}-001-001`,
  sizes: allSizes,
  sizeMapping: headerSizeMapping,
  dom_id: `${slotName}_header_slot`,
  sidebar: false,
  member: member,
});
const sidebarTopSlot = (slotId: string, slotName: string, member: boolean = false): CIAdsMeta => ({
  path: `/${GPT_ID}/${slotId}-002-001`,
  sizes: halfPage,
  sizeMapping: sidebarTopSizeMapping,
  dom_id: `${slotName}_sidebar_top`,
  sidebar: true,
  member: member,
});
const sidebar2ndSlot = (slotId: string, slotName: string, member: boolean = false): CIAdsMeta => ({
  path: `/${GPT_ID}/${slotId}-002-002`,
  sizes: mdRectangle,
  sizeMapping: sidebar2ndSizeMapping,
  dom_id: `${slotName}_sidebar_2nd`,
  sidebar: true,
  member: member,
});
const startPageHeaderSlot = (slotId: string, slotName: string, member: boolean = false): CIAdsMeta => ({
  path: `/${GPT_ID}/001-001-${slotId}`,
  sizes: allSizes,
  sizeMapping: headerSizeMapping,
  dom_id: `start_page_logged_in_header_${slotName}`,
  sidebar: false,
  member: member,
});
const startPageSidebarSlot = (slotId: string, slotName: string, member: boolean = false): CIAdsMeta => ({
  path: `/${GPT_ID}/001-002-${slotId}`,
  sizes: mdRectangle,
  sizeMapping: sidebar2ndSizeMapping,
  dom_id: `start_page_logged_in_sidebar_${slotName}`,
  sidebar: false,
  member: member,
});

export const ADS_INVENTORY: Indexable<CIAdsMeta> = {
  // start page
  start_page_header_slot: headerSlot("001", "start_page", false),
  start_page_sidebar_top: sidebarTopSlot("001", "start_page"),
  start_page_sidebar_2nd: sidebar2ndSlot("001", "start_page"),
  start_page_logged_in_header_desktop: startPageHeaderSlot("002", "desktop", true),
  start_page_logged_in_header_mobile_news: startPageHeaderSlot("003", "mobile_news", true),
  start_page_logged_in_header_mobile_products: startPageHeaderSlot("004", "mobile_products", true),
  start_page_logged_in_sidebar_top: startPageSidebarSlot("003", "top", true),
  start_page_logged_in_sidebar_2nd: startPageSidebarSlot("004", "2nd", true),
  // news center
  news_center_header_slot: headerSlot("002", "news_center"),
  news_center_sidebar_top: sidebarTopSlot("002", "news_center"),
  news_center_sidebar_2nd: sidebar2ndSlot("002", "news_center"),
  // product detail page
  product_detail_header_slot: headerSlot("003", "product_detail"),
  product_detail_sidebar_top: sidebarTopSlot("003", "product_detail"),
  product_detail_sidebar_2nd: sidebar2ndSlot("003", "product_detail"),
  // product search
  product_search_header_slot: headerSlot("006", "product_search"),
  product_search_sidebar_top: sidebarTopSlot("006", "product_search"),
  product_search_sidebar_2nd: sidebar2ndSlot("006", "product_search"),
  // search
  search_header_slot: headerSlot("005", "search"),
  search_sidebar_top: sidebarTopSlot("005", "search"),
  search_sidebar_2nd: sidebar2ndSlot("005", "search"),
  // user profile
  user_profile_header_slot: headerSlot("007", "user_profile"),
  user_profile_sidebar_top: sidebarTopSlot("007", "user_profile"),
  user_profile_sidebar_2nd: sidebar2ndSlot("007", "user_profile"),
  // article detail
  article_detail_header_slot: headerSlot("004", "article_detail"),
  article_detail_sidebar_top: sidebarTopSlot("004", "article_detail"),
  article_detail_sidebar_2nd: sidebar2ndSlot("004", "article_detail"),
  article_detail_inline_guest: {
    path: `/${GPT_ID}/004-003-001`,
    sizes: allSizes,
    sizeMapping: inlineSizeMapping,
    dom_id: "article_detail_inline_guest_{ad_uuid_placeholder}",
    sidebar: false,
    member: false,
  },
  article_detail_inline_member: {
    path: `/${GPT_ID}/004-003-002`,
    sizes: allSizes,
    sizeMapping: inlineSizeMapping,
    dom_id: "article_detail_inline_member_{ad_uuid_placeholder}",
    sidebar: false,
    member: true,
  },
  // product ranking
  product_ranking_header_slot: headerSlot("008", "product_ranking"),
  product_ranking_sidebar_top: sidebarTopSlot("008", "product_ranking"),
  product_ranking_sidebar_2nd: sidebar2ndSlot("008", "product_ranking"),
  // peergroup ranking
  peergroup_ranking_header_slot: headerSlot("009", "peergroup_ranking"),
  peergroup_ranking_sidebar_top: sidebarTopSlot("009", "peergroup_ranking"),
  peergroup_ranking_sidebar_2nd: sidebar2ndSlot("009", "peergroup_ranking"),
  // advisor search
  advisor_search_header_slot: headerSlot("010", "advisor_search"),
  advisor_search_sidebar_top: sidebarTopSlot("010", "advisor_search"),
  advisor_search_sidebar_2nd: sidebar2ndSlot("010", "advisor_search"),
  // webcasts
  webcasts_header_slot: headerSlot("011", "webcasts"),
  webcasts_sidebar_top: sidebarTopSlot("011", "webcasts"),
  webcasts_sidebar_2nd: sidebar2ndSlot("011", "webcasts"),
  // notification center
  notification_center_header_slot: headerSlot("012", "notification_center", true),
  notification_center_sidebar_top: sidebarTopSlot("012", "notification_center", true),
  notification_center_sidebar_2nd: sidebar2ndSlot("012", "notification_center", true),
  // content hubs page
  content_hubs_header_slot: headerSlot("013", "content_hubs"),
  content_hubs_sidebar_top: sidebarTopSlot("013", "content_hubs"),
  content_hubs_sidebar_2nd: sidebar2ndSlot("013", "content_hubs"),
  // portfolio comparisons
  portfolio_comparisons_header_slot: headerSlot("014", "portfolio_comparisons"),
  portfolio_comparisons_sidebar_top: sidebarTopSlot("014", "portfolio_comparisons"),
  portfolio_comparisons_sidebar_2nd: sidebar2ndSlot("014", "portfolio_comparisons"),
};
