/* eslint-disable max-lines */
import PageTypes from "../../app/helpers/page_types.yml";
import { ROUTENAMES } from "@models/constants/routes";
import { ADS_INVENTORY } from "@models/constants/ads_inventory";
import { ContentTrackingIdentifier, CreateRoute } from "./routes.helper";
import { settingsService } from "@services/settings.service";
import { CurrentUser } from "@utils/user";

export const ContentTrackingPageType = (): string => {
  const contentTrackingIdentifier = document.querySelector("meta[name=tracking-identifier]")?.getAttribute("content");

  if (contentTrackingIdentifier === "Webcast") return PageTypes.contents.show.webcast;
  else if (contentTrackingIdentifier === "Podcast") return PageTypes.contents.show.podcast;
  else if (contentTrackingIdentifier === "Video") return PageTypes.contents.show.video;
  else return PageTypes.contents.show.article;
};

export const Routes: CIRouteConfig[] = [
  CreateRoute({
    path: "/",
    name: ROUTENAMES.HOME,
    meta: {
      pageType: PageTypes.start.index,
      trackingIdentifier: "StartPage",
      ads:
        CurrentUser.signedIn && settingsService.hasFlipper("new_start_page")
          ? [
              ADS_INVENTORY.start_page_logged_in_header_desktop,
              ADS_INVENTORY.start_page_logged_in_header_mobile_news,
              ADS_INVENTORY.start_page_logged_in_header_mobile_products,
              ADS_INVENTORY.start_page_logged_in_sidebar_top,
              ADS_INVENTORY.start_page_logged_in_sidebar_2nd,
            ]
          : [
              ADS_INVENTORY.start_page_header_slot,
              ADS_INVENTORY.start_page_sidebar_top,
              ADS_INVENTORY.start_page_sidebar_2nd,
            ],
    },
    component:
      CurrentUser.signedIn && settingsService.hasFlipper("new_start_page")
        ? () => import(/* webpackChunkName: "views_index" */ "@views/index.vue")
        : undefined,
  }),
  CreateRoute({
    path: "/finanzberatung/:location?/:service?",
    name: ROUTENAMES.ADVISOR_SEARCH_INDEX,
    meta: {
      pageType: PageTypes.advisor_search.index,
      trackingIdentifier: "AdvisorSearch",
      wrapContent: true,
      ads: [
        ADS_INVENTORY.advisor_search_header_slot,
        ADS_INVENTORY.advisor_search_sidebar_top,
        ADS_INVENTORY.advisor_search_sidebar_2nd,
      ],
    },
    component: () => import(/* webpackChunkName: "views_advisor_search_index" */ "@views/advisor_search/index.vue"),
  }),
  CreateRoute({
    path: `/company_profiles/:slug`,
    name: ROUTENAMES.COMPANIES_SHOW,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile" */ "@pages/CompanyProfiles/CompanyProfile.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.show,
      trackingIdentifier: "CompanyProfile",
    },
    props: { type: "asset_manager" },
  }),
  CreateRoute({
    path: `/company_profiles/:slug/preview_link`,
    name: ROUTENAMES.COMPANIES_PREVIEW,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile" */ "@pages/CompanyProfiles/CompanyProfile.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.show,
      trackingIdentifier: "CompanyProfile",
    },
  }),
  CreateRoute({
    path: `/company_profiles/:slug/dashboard`,
    name: ROUTENAMES.COMPANIES_DASHBOARD,
    meta: {
      pageType: PageTypes.company_profiles.dashboard,
      trackingIdentifier: "CompanyProfileDashboard",
    },
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Dashboard" */ "@pages/CompanyProfiles/CompanyProfile/Dashboard.vue"
      ),
    props: {
      type: "asset_manager",
      parentRouteName: ROUTENAMES.COMPANIES_SHOW,
    },
  }),
  CreateRoute({
    path: "/company_profiles/:slug/participations",
    name: ROUTENAMES.COMPANIES_PARTICIPATIONS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Participations" */ "@pages/CompanyProfiles/CompanyProfile/Participations.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.participations,
      trackingIdentifier: "CompanyParticipationsOverview",
      wrapContent: true,
    },
  }),
  CreateRoute({
    path: "/company_profiles/:slug/products",
    name: ROUTENAMES.COMPANIES_PRODUCTS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Products" */ "@pages/CompanyProfiles/CompanyProfile/Products.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.products,
      trackingIdentifier: "CompanyProductsOverview",
    },
    props: {
      parentRouteName: ROUTENAMES.COMPANIES_SHOW,
    },
  }),
  CreateRoute({
    path: `/fondsgesellschaften`,
    name: ROUTENAMES.COMPANIES_ASSET_MANAGERS_INDEX,
    meta: {
      pageType: PageTypes.company_profiles.index,
      trackingIdentifier: "CompanyOverview",
    },
    component: () => import(/* webpackChunkName: "pages_CompanyProfiles" */ "@pages/CompanyProfiles.vue"),
    props: { type: "asset_manager" },
  }),
  CreateRoute({
    path: `/fondsgesellschaften/:slug`,
    name: ROUTENAMES.COMPANIES_ASSET_MANAGERS_SHOW,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile" */ "@pages/CompanyProfiles/CompanyProfile.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.show,
      trackingIdentifier: "CompanyProfile",
    },
    props: { type: "asset_manager" /* required for tracking */ },
  }),
  CreateRoute({
    path: `/fondsgesellschaften/:slug/dashboard`,
    name: ROUTENAMES.COMPANIES_ASSET_MANAGERS_DASHBOARD,
    meta: {
      pageType: PageTypes.company_profiles.dashboard,
      trackingIdentifier: "CompanyProfileDashboard",
    },
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Dashboard" */ "@pages/CompanyProfiles/CompanyProfile/Dashboard.vue"
      ),
    props: {
      type: "asset_manager",
      parentRouteName: ROUTENAMES.COMPANIES_ASSET_MANAGERS_SHOW,
    },
  }),
  CreateRoute({
    path: "/fondsgesellschaften/:slug/products",
    name: ROUTENAMES.COMPANIES_ASSET_MANAGERS_PRODUCTS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Products" */ "@pages/CompanyProfiles/CompanyProfile/Products.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.products,
      trackingIdentifier: "CompanyProductsOverview",
    },
    props: {
      parentRouteName: ROUTENAMES.COMPANIES_ASSET_MANAGERS_SHOW,
    },
  }),
  CreateRoute({
    path: "/fondsgesellschaften/:slug/participations",
    name: ROUTENAMES.COMPANIES_ASSET_MANAGERS_PARTICIPATIONS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Participations" */ "@pages/CompanyProfiles/CompanyProfile/Participations.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.participations,
      trackingIdentifier: "CompanyParticipationsOverview",
      wrapContent: false,
    },
    props: {
      parentRouteName: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_SHOW,
    },
  }),
  CreateRoute({
    path: `/vermoegensverwalter`,
    name: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_INDEX,
    meta: {
      pageType: PageTypes.company_profiles.index,
      trackingIdentifier: "CompanyOverview",
    },
    component: () => import(/* webpackChunkName: "pages_CompanyProfiles" */ "@pages/CompanyProfiles.vue"),
    props: { type: "wealth_manager" },
  }),
  CreateRoute({
    path: `/vermoegensverwalter/:slug`,
    name: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_SHOW,
    meta: {
      pageType: PageTypes.company_profiles.show,
      trackingIdentifier: "CompanyProfile",
    },
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile" */ "@pages/CompanyProfiles/CompanyProfile.vue"
      ),
    props: { type: "wealth_manager" /* required for tracking */ },
  }),
  CreateRoute({
    path: `/vermoegensverwalter/:slug/dashboard`,
    name: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_DASHBOARD,
    meta: {
      pageType: PageTypes.company_profiles.dashboard,
      trackingIdentifier: "CompanyProfileDashboard",
    },
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Dashboard" */ "@pages/CompanyProfiles/CompanyProfile/Dashboard.vue"
      ),
    props: {
      type: "wealth_manager",
      parentRouteName: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_SHOW,
    },
  }),
  CreateRoute({
    path: "/vermoegensverwalter/:slug/products",
    name: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_PRODUCTS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Products" */ "@pages/CompanyProfiles/CompanyProfile/Products.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.products,
      trackingIdentifier: "CompanyProductsOverview",
    },
    props: {
      parentRouteName: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_SHOW,
    },
  }),
  CreateRoute({
    path: "/vermoegensverwalter/:slug/participations",
    name: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_PARTICIPATIONS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Participations" */ "@pages/CompanyProfiles/CompanyProfile/Participations.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.participations,
      trackingIdentifier: "CompanyParticipationsOverview",
      wrapContent: false,
    },
    props: {
      parentRouteName: ROUTENAMES.COMPANIES_WEALTH_MANAGERS_SHOW,
    },
  }),
  CreateRoute({
    path: `/finanzdienstleister`,
    name: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_INDEX,
    meta: {
      pageType: PageTypes.company_profiles.index,
      trackingIdentifier: "CompanyOverview",
    },
    component: () => import(/* webpackChunkName: "pages_CompanyProfiles" */ "@pages/CompanyProfiles.vue"),
    props: { type: "financial_service_provider" },
  }),
  CreateRoute({
    path: `/finanzdienstleister/:slug`,
    name: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_SHOW,
    meta: {
      pageType: PageTypes.company_profiles.show,
      trackingIdentifier: "CompanyProfile",
    },
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile" */ "@pages/CompanyProfiles/CompanyProfile.vue"
      ),
    props: { type: "financial_service_provider" /* required for tracking */ },
  }),
  CreateRoute({
    path: `/finanzdienstleister/:slug/dashboard`,
    name: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_DASHBOARD,
    meta: {
      pageType: PageTypes.company_profiles.dashboard,
      trackingIdentifier: "CompanyProfileDashboard",
    },
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile" */ "@pages/CompanyProfiles/CompanyProfile/Dashboard.vue"
      ),
    props: {
      type: "financial_service_provider",
      parentRouteName: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_SHOW,
    },
  }),
  CreateRoute({
    path: "/finanzdienstleister/:slug/participations",
    name: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_PARTICIPATIONS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Participations" */ "@pages/CompanyProfiles/CompanyProfile/Participations.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.participations,
      trackingIdentifier: "CompanyParticipationsOverview",
      wrapContent: false,
    },
    props: {
      parentRouteName: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_SHOW,
    },
  }),
  CreateRoute({
    path: "/finanzdienstleister/:slug/products",
    name: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_PRODUCTS,
    component: () =>
      import(
        /* webpackChunkName: "pages_CompanyProfiles_CompanyProfile_Products" */ "@pages/CompanyProfiles/CompanyProfile/Products.vue"
      ),
    meta: {
      pageType: PageTypes.company_profiles.products,
      trackingIdentifier: "CompanyProductsOverview",
    },
    props: {
      parentRouteName: ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_SHOW,
    },
  }),
  CreateRoute({
    path: "/contents",
    name: ROUTENAMES.CONTENTS_INDEX,
    meta: {
      pageType: PageTypes.contents.index,
      trackingIdentifier: "Newscenter",
    },
  }),
  CreateRoute({
    path: "/contents/podcasts",
    name: ROUTENAMES.CONTENTS_PODCASTS_INDEX,
    meta: {
      pageType: PageTypes.contents.podcasts,
      trackingIdentifier: "Newscenter",
    },
    component: () => import(/* webpackChunkName: "views_contents_podcasts" */ "@frontend/pages/contents/Podcasts.vue"),
  }),
  CreateRoute({
    path: "/webcasts",
    name: ROUTENAMES.WEBCASTS_INDEX,
    meta: {
      pageType: PageTypes.contents.webcasts,
      trackingIdentifier: "Events",
      ads: [ADS_INVENTORY.webcasts_header_slot, ADS_INVENTORY.webcasts_sidebar_top, ADS_INVENTORY.webcasts_sidebar_2nd],
    },
    component: () => import(/* webpackChunkName: "views_contents_webcasts" */ "@frontend/pages/contents/Webcasts.vue"),
  }),
  CreateRoute({
    path: "/contents/videos",
    name: ROUTENAMES.CONTENTS_VIDEOS_INDEX,
    meta: {
      pageType: PageTypes.contents.videos,
      trackingIdentifier: "Newscenter",
    },
    component: () => import(/* webpackChunkName: "views_contents_videos" */ "@frontend/pages/contents/Videos.vue"),
  }),
  CreateRoute({
    path: "/contents/expert_articles",
    name: ROUTENAMES.CONTENTS_EXPERT_ARTICLES,
    meta: {
      pageType: PageTypes.contents.expert_articles,
      trackingIdentifier: "ExpertContentOverview",
    },
    component: () =>
      import(/* webpackChunkName: "views_contents_expert-articles" */ "@frontend/pages/contents/ExpertArticles.vue"),
  }),
  CreateRoute({
    path: "/users/watchlist",
    name: ROUTENAMES.CONTENTS_WATCHLIST,
    meta: {
      gated: true,
      pageType: PageTypes.users.watchlist,
      trackingIdentifier: "UserContentWatchlist",
    },
    component: () => import(/* webpackChunkName: "views_users_watchlist" */ "@frontend/views/users/Watchlist.vue"),
  }),
  CreateRoute({
    path: "/c/:slug",
    name: ROUTENAMES.CONTENTS_SHOW,
    alias: ["/e/:slug"],
    component: () => import(/* webpackChunkName: "views_contents_show" */ "@pages/contents/Show.vue"),
    meta: {
      pageType: ContentTrackingPageType(),
      trackingIdentifier: ContentTrackingIdentifier(),
      ads: [
        ADS_INVENTORY.article_detail_header_slot,
        ADS_INVENTORY.article_detail_sidebar_top,
        ADS_INVENTORY.article_detail_sidebar_2nd,
        ADS_INVENTORY.article_detail_inline_guest,
        ADS_INVENTORY.article_detail_inline_member,
      ],
    },
  }),
  CreateRoute({
    path: "/c/:slug/preview",
    name: ROUTENAMES.CONTENTS_PREVIEW,
    component: () => import(/* webpackChunkName: "views_contents_show" */ "@pages/contents/Show.vue"),
    meta: {
      pageType: ContentTrackingPageType(),
      trackingIdentifier: ContentTrackingIdentifier(),
      ads: [ADS_INVENTORY.article_detail_inline_guest, ADS_INVENTORY.article_detail_inline_member],
    },
    props: {
      isPreview: true,
    },
  }),
  CreateRoute({
    path: "/members/:slug",
    name: ROUTENAMES.USERS_SHOW,
    meta: {
      pageType: PageTypes.users.show,
      trackingIdentifier: "UserProfile",
      wrapContent: false,
      ads: [ADS_INVENTORY.user_profile_sidebar_top, ADS_INVENTORY.user_profile_sidebar_2nd],
    },
    component: () => import(/* webpackChunkName: "views_users_show" */ "@views/users/show.vue"),
  }),
  CreateRoute({
    path: "/members/:slug/edit",
    name: ROUTENAMES.USERS_EDIT,
    component: () => import(/* webpackChunkName: "views_users_edit" */ "@views/users/edit.vue"),
    meta: {
      gated: true,
      pageType: PageTypes.users.edit,
      trackingIdentifier: "UserProfile",
    },
  }),
  CreateRoute({
    path: "/conversations",
    name: ROUTENAMES.CONVERSATIONS_INDEX,
    component: () => import(/* webpackChunkName: "views_conversations_index" */ "@views/conversations/index.vue"),
    meta: {
      pageType: PageTypes.conversations.index,
      trackingIdentifier: "Conversation",
      gated: true,
    },
  }),
  CreateRoute({
    path: "/conversations/:id",
    name: ROUTENAMES.CONVERSATIONS_SHOW,
    component: () => import(/* webpackChunkName: "views_conversations_index" */ "@views/conversations/index.vue"),
    meta: {
      pageType: PageTypes.conversations.show,
      trackingIdentifier: "Conversation",
      gated: true,
    },
  }),
  CreateRoute({
    name: ROUTENAMES.NOTIFICATIONS_INDEX,
    path: "/notifications",
    meta: {
      trackingIdentifier: "Notifications",
      pageType: PageTypes.notifications.index,
      ads: [ADS_INVENTORY.notification_center_sidebar_top, ADS_INVENTORY.notification_center_sidebar_2nd],
    },
    component: () => import(/* webpackChunkName: "views_notifications_index" */ "@views/notifications/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.NEWSCENTER_INDEX,
    path: "/newscenter",
    meta: {
      pageType: PageTypes.newscenter.index,
      trackingIdentifier: "Newscenter",
      ads: [
        ADS_INVENTORY.news_center_header_slot,
        ADS_INVENTORY.news_center_sidebar_top,
        ADS_INVENTORY.news_center_sidebar_2nd,
      ],
    },
    component: () => import(/* webpackChunkName: "views_newscenter_index" */ "@views/newscenter/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.SEARCH_INDEX,
    path: "/search",
    meta: {
      pageType: PageTypes.search.show,
      trackingIdentifier: "SiteSearch",
      ads: [ADS_INVENTORY.search_header_slot, ADS_INVENTORY.search_sidebar_top, ADS_INVENTORY.search_sidebar_2nd],
    },
    component: () => import(/* webpackChunkName: "views_search_index" */ "@views/search/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_WATCHLIST_INDEX,
    path: "/users/followed_products",
    meta: {
      pageType: PageTypes.followed_products.index,
      trackingIdentifier: "WatchlistOverview",
      gated: true,
    },
    component: () =>
      import(/* webpackChunkName: "views_products_followed_index" */ "@views/products/followed/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.COMPARISONS_INDEX,
    path: "/comparisons",
    meta: {
      pageType: PageTypes.comparisons.index,
      trackingIdentifier: "ProductCompareOverview",
      gated: true,
    },
    component: () =>
      import(/* webpackChunkName: "views_products_comparisons_index" */ "@views/products/comparisons/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.COMPARISONS_SHOW,
    path: "/comparisons/:id",
    meta: {
      pageType: PageTypes.comparisons.show,
      trackingIdentifier: "ProductComparison",
    },
    component: () =>
      import(/* webpackChunkName: "views_products_comparisons_show" */ "@views/products/comparisons/show.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_SEARCH_INDEX,
    path: "/products/search",
    meta: {
      pageType: PageTypes.products.search,
      trackingIdentifier: "FundSearch",
      wrapContent: true,
      ads: [
        ADS_INVENTORY.product_search_header_slot,
        ADS_INVENTORY.product_search_sidebar_top,
        ADS_INVENTORY.product_search_sidebar_2nd,
      ],
    },
    component: () => import(/* webpackChunkName: "views_products_search_index" */ "@views/products/search/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_RANKING_START,
    path: "/fonds/fondsranking/start",
    meta: {
      pageType: PageTypes.products.ranking,
      trackingIdentifier: "FundRanking",
      wrapContent: true,
      ads: [
        ADS_INVENTORY.product_ranking_header_slot,
        ADS_INVENTORY.product_ranking_sidebar_top,
        ADS_INVENTORY.product_ranking_sidebar_2nd,
      ],
    },
    component: () => import(/* webpackChunkName: "views_products_ranking_index" */ "@views/products/ranking/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_RANKING_INDEX,
    path: "/fonds/fondsranking/:peergroup?/:product_slug?",
    alias: ["/products/ranking/:peergroup?/:product_slug?"],
    meta: {
      pageType: PageTypes.products.ranking,
      trackingIdentifier: "FundRanking",
      wrapContent: true,
      ads: [
        ADS_INVENTORY.product_ranking_header_slot,
        ADS_INVENTORY.product_ranking_sidebar_top,
        ADS_INVENTORY.product_ranking_sidebar_2nd,
      ],
    },
    component: () => import(/* webpackChunkName: "views_products_ranking_index" */ "@views/products/ranking/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_PEERGROUPS_INDEX,
    path: "/fonds/beste-fonds",
    meta: {
      pageType: "Top Peergroup Products Index Page",
      trackingIdentifier: "TopPeergroupProductsIndexPage",
      ads: [
        ADS_INVENTORY.peergroup_ranking_header_slot,
        ADS_INVENTORY.peergroup_ranking_sidebar_top,
        ADS_INVENTORY.peergroup_ranking_sidebar_2nd,
      ],
    },
    component: () =>
      import(/* webpackChunkname: "views_products_peergroups_index" */ "@views/products/seo/peergroups/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_PEERGROUPS_SHOW,
    path: "/fonds/beste-fonds/:id",
    meta: {
      pageType: "Top Peergroup Products Detail Page",
      trackingIdentifier: "TopPeergroupProductsDetailPage",
      ads: [
        ADS_INVENTORY.peergroup_ranking_header_slot,
        ADS_INVENTORY.peergroup_ranking_sidebar_top,
        ADS_INVENTORY.peergroup_ranking_sidebar_2nd,
      ],
    },
    component: () =>
      import(/* webpackChunkname: "views_products_peergroups_show" */ "@views/products/seo/peergroups/show.vue"),
  }),
  CreateRoute({
    path: "/fonds/*",
    name: ROUTENAMES.CONTENT_HUBS_SHOW,
    meta: {
      pageType: PageTypes.content_routes.index,
      trackingIdentifier: "ContentHubs",
      ads: [
        ADS_INVENTORY.content_hubs_header_slot,
        ADS_INVENTORY.content_hubs_sidebar_top,
        ADS_INVENTORY.content_hubs_sidebar_2nd,
        ADS_INVENTORY.article_detail_inline_guest,
        ADS_INVENTORY.article_detail_inline_member,
      ],
    },
    component: () => import(/* webpackChunkName: "views_content_hubs_show" */ "@views/content_hubs/show.vue"),
  }),
  CreateRoute({
    path: "/fonds",
    name: ROUTENAMES.CONTENT_HUBS_ROOT_SHOW,
    meta: {
      pageType: PageTypes.content_routes.index,
      trackingIdentifier: "ContentHubs",
      ads: [
        ADS_INVENTORY.content_hubs_header_slot,
        ADS_INVENTORY.content_hubs_sidebar_top,
        ADS_INVENTORY.content_hubs_sidebar_2nd,
        ADS_INVENTORY.article_detail_inline_guest,
        ADS_INVENTORY.article_detail_inline_member,
      ],
    },
    component: () => import(/* webpackChunkName: "views_content_hubs_show" */ "@views/content_hubs/show.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_SHOW,
    path: "/p/:id",
    alias: ["/products/:id"],
    meta: {
      pageType: PageTypes.products.show,
      trackingIdentifier: "Product",
      ads: [
        ADS_INVENTORY.product_detail_header_slot,
        ADS_INVENTORY.product_detail_sidebar_top,
        ADS_INVENTORY.product_detail_sidebar_2nd,
      ],
    },
    component: () => import(/* webpackChunkName: "views_products_show" */ "@views/products/show.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRODUCTS_ATTACHMENTS_EDIT,
    path: "/p/:id/attachments_edit",
    alias: ["/products/:id/fund-attachments-edit"],
    meta: {
      pageType: PageTypes.products.attachments_edit,
      trackingIdentifier: "Product Attachments Edit",
    },
    component: () =>
      import(/* webpackChunkName: "products_attachments_edit" */ "@frontend/pages/Products/ProductAttachmentsEdit.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.ONBOARDING_INDEX,
    path: "/onboarding/users",
    meta: {
      pageType: "Onboarding Index",
      trackingIdentifier: "UserOnboarding",
    },
    component: () => import(/* webpackChunkName: "views_onboarding_index" */ "@views/onboarding/users/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.ONBOARDING_SIGN_UP_SUCCESS,
    path: "/onboarding/users/sign_up_success",
    meta: {
      pageType: "Onboarding SignUp Success",
      trackingIdentifier: "UserSignUp",
    },
    component: () =>
      import(/* webpackChunkName: "views_onboarding_index" */ "@views/onboarding/users/SignUpSuccess.vue"),
  }),
  CreateRoute({
    path: "/portfolios",
    name: ROUTENAMES.PORTFOLIOS_INDEX,
    meta: {
      pageType: PageTypes.portfolios.index,
      trackingIdentifier: "PortfolioOverview",
      gated: true,
    },
    component: () => import(/* webpackChunkName: "views_portfolios_index" */ "@views/portfolios/index.vue"),
  }),
  CreateRoute({
    path: "/portfolios/:id",
    name: ROUTENAMES.PORTFOLIOS_SHOW,
    meta: {
      pageType: PageTypes.portfolios.show,
      trackingIdentifier: "Portfolio",
      gated: false,
    },
    component: () => import(/* webpackChunkName: "views_portfolios_show" */ "@views/portfolios/show.vue"),
  }),
  CreateRoute({
    path: "/portfolios/:id/edit",
    name: ROUTENAMES.PORTFOLIOS_EDIT,
    meta: {
      pageType: PageTypes.portfolios.edit,
      trackingIdentifier: "Portfolio",
      gated: true,
    },
    component: () => import(/* webpackChunkName: "views_portfolios_edit" */ "@views/portfolios/edit.vue"),
  }),
  CreateRoute({
    path: "/portfoliosuche",
    name: ROUTENAMES.PORTFOLIOS_COMPARISON_INDEX,
    meta: {
      pageType: PageTypes.portfolios_comparison.index,
      trackingIdentifier: "Portfolios Comparison",
      wrapContent: false,
      ads: [
        ADS_INVENTORY.portfolio_comparisons_header_slot,
        ADS_INVENTORY.portfolio_comparisons_sidebar_top,
        ADS_INVENTORY.portfolio_comparisons_sidebar_2nd,
      ],
    },
    component: () =>
      import(/* webpackChunkName: "views_portfolios_comparisons_index" */ "@views/portfolios/comparisons/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.EVENTS_INDEX,
    path: "/social_events",
    meta: {
      pageType: PageTypes.social_events.index,
      trackingIdentifier: "Event",
      wrapContent: true,
    },
    component: () => import(/* webpackChunkName: "views_events_index" */ "@views/social_events/Index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.EVENTS_SHOW,
    path: "/social_events/:id",
    meta: {
      pageType: PageTypes.social_events.show,
      trackingIdentifier: "Event",
    },
    component: () => import(/* webpackChunkName: "views_events_show" */ "@views/social_events/show.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.USERS_SETTINGS,
    path: "/user/settings",
    meta: {
      pageType: PageTypes.settings.index,
      trackingIdentifier: "UserSetting",
      wrapContent: true,
    },
    component: () => import(/* webpackChunkName: "views_settings_index" */ "@views/settings/index.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.USERS_PRIVACY_SETTINGS,
    path: "/user/settings/privacy",
    meta: {
      pageType: PageTypes.privacies.show,
      trackingIdentifier: "UserSetting",
    },
  }),
  CreateRoute({
    name: ROUTENAMES.USERS_SETTINGS_SUBSCRIPTION_MANAGEMENT,
    path: "/user/settings/subscription_management",
    meta: {
      pageType: PageTypes.settings.subscription_management,
      trackingIdentifier: "UserSetting",
      wrapContent: false,
    },
    component: () =>
      import(/* webpackChunkName: "views_settings_subscriptions" */ "@views/settings/subscription_management.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.BCA_LOGIN,
    path: "/users/auth/bca/login",
    meta: {
      trackingIdentifier: "BCALogin",
      pageType: PageTypes.users.auth.bca.login,
    },
    component: () => import(/* webpackChunkName: "bca_login_page" */ "@frontend/views/users/auth/bca/login.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.PRIVACY_POLICY,
    path: "/datenschutz",
    meta: {
      trackingIdentifier: "PrivacyPolicy",
      pageType: PageTypes.pages.datenschutz,
    },
    component: () => import(/* webpackChunkName: "privacy_policy_page" */ "@frontend/pages/PrivacyPolicy.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.IMPRINT,
    path: "/impressum",
    meta: {
      trackingIdentifier: "Impressum",
      pageType: PageTypes.pages.impressum,
    },
    component: () => import(/* webpackChunkName: "imprint_page" */ "@frontend/pages/Imprint.vue"),
  }),
  CreateRoute({
    name: ROUTENAMES.TERMS_AND_CONDITIONS,
    path: "/agb",
    meta: {
      trackingIdentifier: "TermsAndConditions",
      pageType: PageTypes.pages.agb,
    },
    component: () =>
      import(/* webpackChunkName: "terms_and_conditions_page" */ "@frontend/pages/TermsAndConditions.vue"),
  }),
];
